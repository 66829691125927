import { Button } from '@tackle-io/platform-ui';
import { useFormikContext, setNestedObjectValues } from 'formik';
import { isEmpty } from 'lodash';
import { pendoIdGenerator } from 'utils/idGenerator';
import { cosellDraftFormButtonElements } from 'utils/pendoEnums';
import { useOpportunityFormStyles } from './UnifiedOpportunityForm.styles';
import { ActiveOpportunityFormAction } from './UnifiedOpportunityFormShell';
import { UnifiedOpportunityFormValues } from './UnifiedOpportunityFormValues';
import { OpportunityResponse } from '../../types/responses/OpportunityResponse';
import { useCoSellHistory } from '../../hooks';
import { OpportunityStatusEnum } from '../../types/enums';
import { COSELL_DASHBOARD_PATH } from '../../utilities/constants';

interface UnifiedOpportunityFormButtonSectionProps {
  onSubmit: (values: UnifiedOpportunityFormValues) => Promise<void>;
  onSaveAsDraft?: (values: UnifiedOpportunityFormValues) => Promise<void>;
  onCancel: () => void;
  onDelete: () => void;
  opportunity: OpportunityResponse;
  activeOpportunityFormAction: ActiveOpportunityFormAction;
}

export const UnifiedOpportunityFormButtonSection = ({
  onSubmit,
  onSaveAsDraft,
  onCancel,
  onDelete,
  opportunity,
  activeOpportunityFormAction,
}: UnifiedOpportunityFormButtonSectionProps) => {
  const classes = useOpportunityFormStyles();
  const history = useCoSellHistory();
  const { values, dirty, validateForm, setTouched, submitForm } =
    useFormikContext<UnifiedOpportunityFormValues>();

  const isDraft =
    opportunity?.status === OpportunityStatusEnum.DRAFT ||
    opportunity?.status === OpportunityStatusEnum.INTERNAL_REVIEW;
  const saveBtnText =
    opportunity?.status === OpportunityStatusEnum.DRAFT || !opportunity?.id
      ? 'Save draft & exit'
      : 'Save review & exit';
  const deleteBtnText =
    opportunity?.status === OpportunityStatusEnum.DRAFT
      ? 'Delete draft'
      : 'Delete review';
  const isSaveForDraftEnabled = isDraft || !opportunity?.id;
  const isReSubmission = !isDraft && !!opportunity?.id;

  const redirectToCoSell = () => {
    const pathHome = COSELL_DASHBOARD_PATH;
    history.push({
      pathname: pathHome,
      search: history.location.state?.coSellSearchParams,
    });
  };

  const handleCancel = () => {
    dirty ? onCancel() : redirectToCoSell();
  };

  const handleSubmit = async () => {
    const errors = await validateForm();
    // submitForm() is only meant to update submitCount so that we know to scroll to errors
    submitForm();
    if (isEmpty(errors)) {
      onSubmit(values);
    } else {
      setTouched(setNestedObjectValues(errors, true));
    }
  };

  const handleSaveDraft = () => {
    onSaveAsDraft(values);
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <>
      <div className={classes.cosellFormButtonsContainer}>
        <div className={classes.cosellFormButtonContainer}>
          {isDraft ? (
            <div className={classes.cosellFormDeleteDraftButton}>
              <Button
                id={pendoIdGenerator(cosellDraftFormButtonElements.DELETE)}
                type="button"
                size="medium"
                name="delete"
                appearance="destructive"
                onClick={handleDelete}
                disabled={!!activeOpportunityFormAction}
              >
                {deleteBtnText}
              </Button>
            </div>
          ) : null}
          {isSaveForDraftEnabled ? (
            <Button
              appearance="primary"
              id={pendoIdGenerator(
                cosellDraftFormButtonElements.SAVE_DRAFT_AND_EXIT,
              )}
              name="draft"
              onClick={handleSaveDraft}
              size="medium"
              type="button"
              variant="outlined"
              loading={activeOpportunityFormAction === 'saving_draft'}
              disabled={!!activeOpportunityFormAction}
            >
              {saveBtnText}
            </Button>
          ) : null}
        </div>
        <div>
          <div className={classes.cosellFormCancelButton}>
            <Button
              id={pendoIdGenerator(cosellDraftFormButtonElements.CANCEL)}
              type="button"
              size="medium"
              name="cancel"
              variant="outlined"
              appearance="secondary"
              onClick={handleCancel}
              disabled={!!activeOpportunityFormAction}
            >
              Cancel
            </Button>
          </div>
          <div className={classes.cosellFormButtonContainer}>
            <Button
              id={pendoIdGenerator(
                cosellDraftFormButtonElements.SUBMIT_TO_CLOUD,
              )}
              type="button"
              size="medium"
              name="submit"
              variant="contained"
              appearance="primary"
              onClick={handleSubmit}
              loading={activeOpportunityFormAction === 'submitting'}
              disabled={!!activeOpportunityFormAction}
            >
              {isReSubmission ? 'Re-submit' : 'Submit to cloud'}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
