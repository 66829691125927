import cosellClient from 'packages/cosell/api/cosellClient';
import { AwsEnumValuesResponse } from 'packages/cosell/src/types/responses/AwsEnumResponse';
import { solutionsApiToFormFormatter } from 'packages/cosell/src/utilities/formatSolutions';
import { createContext, useContext, useEffect, useState } from 'react';

interface OpportunityContextProps {
  vendorId: string;
  token: string;
  solutionList: Array<{ value: string; display: string }>;
  awsEnumValues: AwsEnumValuesResponse;
}

export const OpportunityContext = createContext<OpportunityContextProps>({
  vendorId: null,
  token: null,
  solutionList: null,
  awsEnumValues: null,
});

export interface OpportunityProviderProps {
  vendorId?: string;
  token?: string;
  opportunityId?: string;
  children?: React.ReactElement;
}

export const OpportunityProvider = ({
  vendorId,
  token,
  children,
}: OpportunityProviderProps) => {
  const [solutionList, setSolutionList] = useState<
    Array<{ display: string; value: string }>
  >([]);
  const [awsEnumValues, setAwsEnumValues] =
    useState<AwsEnumValuesResponse>(null);

  const authorizedCosellClient = cosellClient(token);

  useEffect(() => {
    const getVendorSolutions = async () => {
      const vendorSolutionsData =
        await authorizedCosellClient?.getVendorSolutionsByVendorAndCloud(
          vendorId,
          'ace',
        );
      const solutionList = solutionsApiToFormFormatter(vendorSolutionsData);
      setSolutionList(solutionList);
    };
    if (solutionList?.length === 0 && authorizedCosellClient) {
      getVendorSolutions();
    }
  }, [vendorId, authorizedCosellClient, solutionList]);

  useEffect(() => {
    const getAwsCoSellEnumValues = async () => {
      const awsCoSellEnumValues =
        await authorizedCosellClient?.getAwsCoSellEnumValues();
      setAwsEnumValues(awsCoSellEnumValues);
    };
    if (awsEnumValues === null && authorizedCosellClient) {
      getAwsCoSellEnumValues();
    }
  });

  return (
    <OpportunityContext.Provider
      value={{
        vendorId,
        token,
        solutionList,
        awsEnumValues,
      }}
    >
      {children}
    </OpportunityContext.Provider>
  );
};

export function useOpportunity() {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error('useOpportunity must be used within a OpportunityProvider');
  }
  return context;
}
