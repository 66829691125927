import { authStore } from '../Auth';
import ky from 'ky';
import {
  deserialize,
  deserializeMarketplaceOffer,
  MarketplaceOfferResponseJSON,
  MarketplaceOffer,
  PrivateOffer,
} from './typings';
import { Cloud } from 'utils/cloudTypes';

type AuthTokenProvider = () => Promise<string>;

const setBearerHeader =
  (getAuthToken: AuthTokenProvider) => async (request: Request) => {
    try {
      const token = await getAuthToken();
      request.headers.set('Authorization', `Bearer ${token}`);
    } catch (e) {
      console.log('error', e);
    }
  };

const setContentTypeHeader =
  (contentType: string) => async (request: Request) => {
    request.headers.set('Content-Type', contentType);
  };

export enum ResponseType {
  CSV = 'csv',
  JSON = 'json',
}

const jsonContentType = 'application/json';

const contentTypeHeaderByResponseType = {
  [ResponseType.CSV]: 'text/csv',
  [ResponseType.JSON]: jsonContentType,
};

export const buildOffersApi = (
  getAuthToken: AuthTokenProvider,
  responseType: ResponseType = ResponseType.JSON,
) => {
  const contentType =
    contentTypeHeaderByResponseType[responseType] ?? jsonContentType;

  return ky.extend({
    prefixUrl: process.env.REACT_APP_OFFERS_API_URL,
    timeout: 60000,
    hooks: {
      beforeRequest: [
        setBearerHeader(getAuthToken),
        setContentTypeHeader(contentType),
      ],
    },
  });
};

const getAuthToken = async () => authStore.token;

const getOffersApi = (responseType?: ResponseType) =>
  buildOffersApi(getAuthToken, responseType);

export const OFFERS_API_URL = 'public/v1/private-offers';

export interface PrivateOfferApiOptions {
  searchParams: URLSearchParams;
  signal?: AbortSignal;
}

const snakeCaseLetter = (letter: string, index: number) => {
  const lowercaseLetter = letter.toLowerCase();

  return index === 0 ? lowercaseLetter : `_${lowercaseLetter}`;
};

const toSnakeCase = (value: string): string =>
  value.replace(/[A-Z]/g, snakeCaseLetter);

const offersAPISearchParamKeyOverrides = {
  offerType: 'type',
  create_date__gt: 'created_at_gt',
  create_date__lt: 'created_at_lt',
};

const toOffersAPISearchParam = (entry: [string, string]): [string, string] => {
  const filterKey = entry[0] ?? '';
  const key = offersAPISearchParamKeyOverrides[filterKey] ?? filterKey;

  return [toSnakeCase(key), entry[1]];
};

type OffersAPISearchParams = { [k: string]: string };

const collectSearchParams = (
  a: OffersAPISearchParams,
  b: [string, string],
): OffersAPISearchParams => ({
  ...a,
  [b[0]]: b[1],
});

const snakeCaseSearchParams = (searchParams: URLSearchParams) => {
  const offersApiSearchParams = Array.from(searchParams.entries())
    .map(toOffersAPISearchParam)
    .reduce(collectSearchParams, {});

  return new URLSearchParams(offersApiSearchParams);
};

const createPrivateOffersApiOptions = (
  apiOptions: PrivateOfferApiOptions,
): PrivateOfferApiOptions => {
  const searchParams = snakeCaseSearchParams(apiOptions.searchParams);
  const signal = apiOptions.signal ?? new AbortController().signal;

  return { searchParams, signal };
};

export const getPrivateOffersResponse = async (
  apiOptions: PrivateOfferApiOptions,
  responseType?: ResponseType,
): Promise<Response> => {
  const offersApi = getOffersApi(responseType);
  const options = createPrivateOffersApiOptions(apiOptions);

  return offersApi.get(OFFERS_API_URL, options);
};

const deserializePrivateOffers = async (
  response: Response,
): Promise<PrivateOffer[]> => {
  const { data: privateOffersJson } = await response.json();

  return privateOffersJson.map(deserialize);
};

export const getPrivateOffers = async (
  apiOptions: PrivateOfferApiOptions,
): Promise<PrivateOffer[]> => {
  const response = await getPrivateOffersResponse(apiOptions);

  return deserializePrivateOffers(response);
};

export const fetchMarketplaceOffer = async (
  cloud: Cloud,
  offerRef: string,
  searchParams?: URLSearchParams,
): Promise<MarketplaceOffer> => {
  const snakeCasedSearchParams = searchParams
    ? snakeCaseSearchParams(searchParams)
    : undefined;
  const getOptions = { searchParams: snakeCasedSearchParams };

  return buildOffersApi(getAuthToken)
    .get(`public/v1/marketplace/${cloud}/offers/${offerRef}`, getOptions)
    .json()
    .then((res: MarketplaceOfferResponseJSON) =>
      deserializeMarketplaceOffer(res.data),
    );
};
