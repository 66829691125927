export const COSELL_DASHBOARD_PATH = '/co-sell';
export enum UnifiedOpportunityView {
  CO_SELL_DETAILS = 'CoSellDetails',
  CREATE_NEW_CO_SELL_FORM = 'CreateNewCoSellForm',
  EDIT_CO_SELL_FORM = 'EditCoSellForm',
}

export const getUnifiedOpportunityView = ({
  url,
  opportunityId,
}: {
  url: string;
  opportunityId?: string;
}): UnifiedOpportunityView => {
  const isEdit = url.endsWith('/edit');

  if (!opportunityId) {
    return UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM;
  } else if (opportunityId && isEdit) {
    return UnifiedOpportunityView.EDIT_CO_SELL_FORM;
  } else if (opportunityId && !isEdit) {
    return UnifiedOpportunityView.CO_SELL_DETAILS;
  }
  return null;
};
