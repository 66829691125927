import { useEffect, useState } from 'react';
import CanvasSdk from './lib/CanvasSdk';
import {
  getTackleSessionFromOtk,
  IOneTimeKeyExchangeResponse,
} from './lib/CanvasSession';
import {
  CanvasBulkCreateCosell,
  CanvasCreateCoSell,
  CanvasCoSellDetails,
} from '../co-sell/src';
import { Loader } from '@tackle-io/platform-ui';

const ONE_TIME_KEY_PARAM = 'otk';

const CanvasApp = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const otk = urlParams.get(ONE_TIME_KEY_PARAM);

  const [tackleSession, setTackleSession] =
    useState<IOneTimeKeyExchangeResponse>();
  const [isLoadingTackleSession, setIsLoadingTackleSession] = useState(true);

  useEffect(() => {
    async function callGetTackleSessionFromOtk() {
      const tackleSession = await getTackleSessionFromOtk(otk);
      setTackleSession(tackleSession);
      setIsLoadingTackleSession(false);
    }

    if (!tackleSession && otk) {
      callGetTackleSessionFromOtk();
    }
  }, [tackleSession, otk]);

  useEffect(() => {
    if (
      !tackleSession ||
      !tackleSession.context ||
      !tackleSession.context.client
    )
      return;
    CanvasSdk.canvas.client.autogrow(tackleSession.context.client, true, 100);
  }, [tackleSession]);

  if (isLoadingTackleSession) {
    return <Loader />;
  }

  if (
    !tackleSession ||
    !tackleSession.context ||
    !tackleSession.context.action
  ) {
    return <div>Unknown Context Provided</div>;
  }

  switch (tackleSession.context.action) {
    case 'CoSellDetails':
      return <CanvasCoSellDetails tackleSession={tackleSession} />;
    case 'CreateNewCoSellForm':
      return <CanvasCreateCoSell tackleSession={tackleSession} />;
    case 'BulkCreateCoSell':
      return <CanvasBulkCreateCosell tackleSession={tackleSession} />;
    default:
      return <div>Unknown Context Provided</div>;
  }
};

export default CanvasApp;
