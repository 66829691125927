import React from 'react';
import { Grid } from 'vendor/material';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import InfoItem from 'packages/cosell/src/components/InfoItem/InfoItem';
import DetailsSectionCard from 'packages/cosell/src/components/DetailsSectionCard/DetailsSectionCard';
// import { DetailsSectionCard, InfoItem } from 'packages/cosell/src/components';

interface AceAdditionalDetailsProps {
  additionalDetails: AdditionalDetails;
}

// the reason here is interface instead of type is because it is not matching any object in the api
// other sections are matching the api response object, and they will be moved into the types folder
interface AdditionalDetails {
  awsAccountId: string;
  additionalComments: string;
  partnerOpportunityIdentifier: string;
  competitiveTracking: string;
}

const AceAdditionalDetails: React.FC<AceAdditionalDetailsProps> = ({
  additionalDetails,
}) => {
  return (
    <DetailsSectionCard title="Additional details" icon={<OfficeBuilding />}>
      <Grid container spacing={2}>
        <InfoItem
          itemTitle="Partner CRM unique identifier"
          value={additionalDetails?.partnerOpportunityIdentifier}
        />

        <InfoItem
          itemTitle="Competitive tracking"
          value={additionalDetails?.competitiveTracking}
        />

        <InfoItem
          itemTitle="Additional comments"
          value={additionalDetails?.additionalComments}
        />
        <InfoItem
          itemTitle="AWS account ID"
          value={additionalDetails?.awsAccountId}
        />
      </Grid>
    </DetailsSectionCard>
  );
};

export default AceAdditionalDetails;
