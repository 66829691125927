import UnifiedOpportunityFormShell from '../UnifiedOpportunityFormShell';
import {
  DisplayCloudType,
  OpportunityStatusEnum,
} from 'packages/cosell/src/types/enums';
import { ampli } from 'utils/analytics/ampli';
import AceOpportunityFormFields from './AceOpportunityFormFields';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import cosellClient from 'packages/cosell/api/cosellClient';
import { convertAceFormValuesToCreateRequest } from 'packages/cosell/src/utilities/typeConverters/convertAceFormValuesToCreateRequest';
import { OpportunityResponse } from 'packages/cosell/src/types/responses/OpportunityResponse';
import { aceOpportunityFormValidationSchema } from './aceOpportunityFormValidationSchema';
// import { mockInitialValues } from '../FormFieldsConstants';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';

interface AceOpportunityFormProps {
  opportunity?: OpportunityResponse;
}
const AceOpportunityForm = ({ opportunity }: AceOpportunityFormProps) => {
  const { token } = useOpportunity();

  const authorizedCosellClient = cosellClient(token);

  const handleCreateOrUpdateOpportunity = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    const requestBody = convertAceFormValuesToCreateRequest(values);
    // TODO: If opportunityId exists, use updateOpportunity
    return await authorizedCosellClient.createOpportunity(requestBody);
  };

  const handleSubmitAceOpportunityToCloud = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    try {
      // Set Status to Submitting to Trigger Submission to Cloud
      values.status = OpportunityStatusEnum.SUBMITTING;
      const submittedOpportunity = await handleCreateOrUpdateOpportunity(
        values,
      );
      ampli.outboundSubmitted({ cloud: 'aws' });
      return submittedOpportunity;
    } catch (error) {
      throw error;
    }
  };

  const handleSaveAceOpportunityAsDraft = async (
    values: CreateAceOpportunityFormValues,
  ) => {
    // Set Status to Draft or Internal review to Trigger Draft Creation
    values.status =
      values.status && values.status === OpportunityStatusEnum.INTERNAL_REVIEW
        ? OpportunityStatusEnum.INTERNAL_REVIEW
        : OpportunityStatusEnum.DRAFT;
    const saveDraftOpportunity = handleCreateOrUpdateOpportunity(values);
    return saveDraftOpportunity;
  };

  return (
    <UnifiedOpportunityFormShell
      opportunity={opportunity}
      onSubmit={handleSubmitAceOpportunityToCloud}
      onSaveAsDraft={handleSaveAceOpportunityAsDraft}
      initialValues={{}}
      // initialValues={mockInitialValues}
      provider={DisplayCloudType.AWS}
      validationSchema={aceOpportunityFormValidationSchema}
    >
      <AceOpportunityFormFields opportunity={opportunity} />
    </UnifiedOpportunityFormShell>
  );
};

export default AceOpportunityForm;
