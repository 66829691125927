import { Typography, Breadcrumbs, Link } from 'vendor/material';

// ASSETS
import useStyles from './PageHeader.styles';

// TODO: Added check for breadcrumbs prop but breadcrumbs currently aren't used anywhere; Confirm with UI/UX teams whether they will be implemented one day
const PageHeader = ({ title, breadcrumbs, ...props }) => {
  const classes = useStyles();

  return (
    <div {...props}>
      {breadcrumbs.length > 0 && (
        <Breadcrumbs
          aria-label="breadcrumb"
          classes={{ li: classes.breadcrumbItem }}
        >
          {breadcrumbs.map((breadcrumb) => {
            if (breadcrumb.href) {
              return (
                <Link
                  key={breadcrumb.name}
                  color="inherit"
                  href="/"
                  onClick={() => {}}
                >
                  {breadcrumb.name}
                </Link>
              );
            }

            return (
              <Typography key={breadcrumb.name} color="textPrimary">
                {breadcrumb.name}
              </Typography>
            );
          })}
        </Breadcrumbs>
      )}
      <Typography component="h1" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
};

export default PageHeader;
