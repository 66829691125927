import { fontWeightMedium } from 'utils/fontWeightConstants';
import { makeStyles } from 'vendor/material';
const useStyles = makeStyles((theme) => ({
  container: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(14),
    fontWeight: fontWeightMedium,
    padding: theme.spacing(0.9, 1),
    borderRadius: 4,
    display: 'inline-flex',
  },
  standard: {
    backgroundColor: theme.palette.NEUTRAL030,
    color: theme.palette.NEUTRAL700,
  },
  blue: {
    backgroundColor: theme.palette.BLUE050,
    color: theme.palette.BLUE400,
  },
  green: {
    backgroundColor: theme.palette.GREEN050,
    color: theme.palette.GREEN400,
  },
  red: {
    backgroundColor: theme.palette.RED050,
    color: theme.palette.RED400,
  },
  orange: {
    backgroundColor: theme.palette.YELLOW075,
    color: theme.palette.RED300,
  },
  purple: {
    backgroundColor: theme.palette.PURPLE050,
    color: theme.palette.PURPLE300,
  },
}));

export default useStyles;
