import ky from 'ky';
import { get } from 'lodash';
import { reportError } from 'utils/monitor';
import { ErrorResponse } from '../src/types';
import { CreateAceOpportunityRequest } from '../src/types/requests/AceOpportunityRequest';
import { AwsEnumValuesResponse } from '../src/types/responses/AwsEnumResponse';
import { AceOpportunityResponse } from '../src/types/responses/AceOpportunityResponse';
// import data from './mockOppDetails.json';

// our calls to create/update ACE opportunities are rather expensive
// unfortunately, the speed at which ACE processes the opportunities we send it is out of our control
// our API service waits up to 20 seconds for ACE to process our opportunities, we need to provide enough time for that to complete
const COSELL_API_TIMEOUT = 25000;
const TIMEOUT_STATUS_CODE = 408;

const getErrorResponse = async (error) => {
  let errorResponse: ErrorResponse = {
    httpStatusCode: get(error, 'response.status', 500),
    message: get(error, 'message', 'An error was encountered'),
    detail: get(error, 'message', 'An error was encountered'),
  };
  if (error instanceof ky.HTTPError) {
    const errorJson = await error.response.json();
    errorResponse.detail = errorJson.detail;
  } else if (error instanceof ky.TimeoutError) {
    errorResponse.httpStatusCode = TIMEOUT_STATUS_CODE;
  }
  return errorResponse;
};

const makeCosellApiClient = (token) => {
  if (!token) {
    return null;
  }
  const cosellApiConfig = {
    prefixUrl: process.env.REACT_APP_TACKLE_CO_SELL_V3_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
    hooks: {
      beforeRequest: [
        async (request) => {
          request.headers.set('Authorization', `Bearer ${token}`);
          return request;
        },
      ],
    },
  };
  const cosellHttpClient = ky.create(cosellApiConfig);

  const cosellClient = {
    async createOpportunity(
      requestBody: CreateAceOpportunityRequest,
    ): Promise<any | ErrorResponse> {
      try {
        const result = await cosellHttpClient
          .post(`api/opportunities`, {
            body: JSON.stringify(requestBody),
            timeout: COSELL_API_TIMEOUT,
          })
          .json();
        return result;
      } catch (error) {
        reportError(error);
        const err = await getErrorResponse(error);
        // @ts-ignore
        throw new Error(`An error was encountered: ${error?.message}`, {
          cause: err,
        });
      }
    },

    async getAwsCoSellEnumValues(): Promise<AwsEnumValuesResponse> {
      const result = await cosellHttpClient
        .get(`api/picklist-options`, {
          timeout: COSELL_API_TIMEOUT,
        })
        .json();

      return result as AwsEnumValuesResponse;
    },

    async getOpportunityByIdV3(
      opportunityId: string,
    ): Promise<AceOpportunityResponse | ErrorResponse> {
      // return data;

      return await cosellHttpClient
        .get(`api/opportunities/${opportunityId}`)
        .then(async (response) => {
          const jsonResponse = await response.json();
          return jsonResponse;
        })
        .catch(async (error) => {
          reportError(error);
          const err = await getErrorResponse(error);
          throw err;
        });
    },

    async getVendorSolutionsByVendorAndCloud(
      vendorId: string,
      cloud: string,
    ): Promise<any | ErrorResponse> {
      return [
        {
          id: 'ec93357a-a1a6-4780-b746-141cec813706',
          externalId: '123',
          solutionName: '123',
          isADefaultSolution: false,
        },
        {
          id: '4d034b02-e7a0-414e-8840-c720b3aed567',
          externalId: 'S-1234567',
          solutionName: 'Test Solution',
          isADefaultSolution: true,
        },
        {
          id: '4d034b02-e7a0-414e-8840-c720b3aed568',
          externalId: 'S-0014870',
          solutionName: 'S-0014870',
          isADefaultSolution: false,
        },
      ];
      // TODO: Use API to get solutions
      // try {
      //   const result = await cosellHttpClient
      //     .get(`api/vendor/${vendorId}/solutions`)
      //     .json();
      //   return result;
      // } catch (error) {
      //   if (error instanceof ky.HTTPError || error instanceof ky.TimeoutError) {
      //     const err = await getErrorResponse(error);
      //     // @ts-ignore
      //     throw new Error('An error was encountered', { cause: err });
      //   }
      // }
    },
  };
  return cosellClient;
};

const cosellClient = (token) => makeCosellApiClient(token);
export default cosellClient;
