import { CreateAceOpportunityFormValues } from './AceOpportunityForm/AceOpportunityFormValues';

export const OTHER = 'Other';

export const mockInitialValues: CreateAceOpportunityFormValues = {
  // Section 1: Customer Details
  dunsNumber: '123456789',
  customerCompanyName: 'Victor Inc. Company',
  industry: 'Education',
  customerWebsite: 'www.tackle.com',
  countryCode: 'US',
  postalCode: '94610',
  nationalSecurity: null,
  state: 'California',
  city: 'Oakland',
  address: '123 Palm Ave.',
  // Section 2: Project details
  partnerNeedType: 'Co-sell with AWS',
  primaryNeedsFromAws: ['Co-Sell - Competitive Information'],
  opportunityType: 'Flat Renewal',
  projectDescription: 'Trying to get a better solution',
  salesActivities: [
    'Initialized discussions with customer',
    'Customer has shown interest in solution',
  ],
  projectTitle: 'Victor Inc. Company opportunity',
  solutions: ['S-0014870'],
  awsProducts: ['AmazonRedshift'],
  useCase: 'Analytics',
  deliveryModel: ['SaaS or PaaS'],
  expectedMonthlyRevenue: 123,
  targetCloseDate: '2099-09-03T07:00:00.000Z',
  apnPrograms: ['APN Immersion Days'],
  nextStep: 'Wait for approval',
  // Section 3: Marketing details
  isOppFromMarketingActivity: 'Yes',
  campaignName: 'London Marketing Event',
  marketingActivityUseCases: ['Business Applications'],
  marketingActivityChannel: ['Live Event'],
  isMarketingDevelopmentFunded: 'Yes',
  // Section 4: Additional details
  competitiveTracking: 'Oracle Cloud',
  awsAccountID: '123456789012',
  additionalComments: 'Customer interested in lower pricing',
  // Section 5: Customer contact
  customerFirstName: 'John',
  customerLastName: 'Doe',
  customerTitle: 'CTO',
  customerEmail: 'johndoe@victorcompany.com.invalid',
  customerPhone: '111222333',
  // Section 6: Partner contact
  primaryContactPhone: '111222334',
  primaryContactFirstName: 'James',
  primaryContactLastName: 'Smith',
  primaryContactTitle: 'CRO',
  primaryContactEmail: 'james.smith@company.com.invalid',
};
