import { CreateAceOpportunityFormValues } from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityFormValues';
import {
  LifeCycle,
  Marketing,
  OpportunityRequestCustomer,
  Project,
  OpportunityRequestAccount,
  CreateAceOpportunityRequest,
  AccessControls,
} from '../../types/requests/AceOpportunityRequest';

export const getAccount = (values: CreateAceOpportunityFormValues) => {
  const account: OpportunityRequestAccount = {
    address: getAddress(values),
    duns: values.dunsNumber,
    awsAccountId: values.awsAccountID,
    companyName: values.customerCompanyName,
    industry: values.industry,
    otherIndustry: values.industryOther,
    websiteUrl: values.customerWebsite,
  };
  return account;
};

export const getContact = (values: CreateAceOpportunityFormValues) => {
  const contact = {
    firstName: values.customerFirstName,
    lastName: values.customerLastName,
    email: values.customerEmail,
    phone: values.customerPhone ? `+${values.customerPhone}` : null,
    businessTitle: values.customerTitle,
  };
  return contact;
};

export const getAccessControl = (
  values: CreateAceOpportunityFormValues,
): AccessControls => {
  const accessControls: AccessControls = {
    nationalSecurity: values.nationalSecurity,
  };

  return accessControls;
};

export const getCustomer = (
  values: CreateAceOpportunityFormValues,
): OpportunityRequestCustomer => {
  const customer: OpportunityRequestCustomer = {
    account: getAccount(values),
    contact: getContact(values),
  };

  return customer;
};

const getLifecycle = (values: CreateAceOpportunityFormValues) => {
  const lifeCycle: LifeCycle = {
    nextSteps: values.nextStep,
    targetCloseDate: values.targetCloseDate,
  };
  return lifeCycle;
};

const getMarketing = (values: CreateAceOpportunityFormValues) => {
  const marketing: Marketing = {
    awsFundingUsed: values.isMarketingDevelopmentFunded,
    campaignName: values.campaignName,
    channels: values.marketingActivityChannel,
    source:
      values.isOppFromMarketingActivity === 'Yes' ? 'Marketing Activity' : null, // todo: verify
    useCases: values.marketingActivityUseCases,
  };
  return marketing;
};

const getProject = (values: CreateAceOpportunityFormValues) => {
  const project: Project = {
    additionalComments: values.additionalComments,
    apnPrograms: values.apnPrograms,
    competitorName: values.competitiveTracking,
    customerBusinessProblem: values.projectDescription,
    customerUseCase: values.useCase,
    deliveryModels: values.deliveryModel,
    expectedMonthlyAwsRevenue: {
      amount: values.expectedMonthlyRevenue,
      currencyCode: 'USD',
    },
    otherCompetitorNames: values.otherCompetitorNames,
    salesActivities: values.salesActivities,
    title: values.projectTitle,
    otherSolutionDescription: values.otherSolutionDescription,
  };
  return project;
};

// For Create, we initialize with just 1
const getPartnerOpportunityTeam = (values: CreateAceOpportunityFormValues) => [
  {
    email: values.primaryContactEmail,
    firstName: values.primaryContactFirstName,
    lastName: values.primaryContactLastName,
    phone: values.primaryContactPhone ? `+${values.primaryContactPhone}` : null,
    businessTitle: values.primaryContactTitle,
  },
];
export const getAddress = (values: CreateAceOpportunityFormValues) => {
  const address = {
    city: values.city,
    countryCode: values.countryCode,
    postalCode: values.postalCode,
    stateOrRegion: values.state,
    streetAddress: values.address,
  };
  return address;
};

export const convertAceFormValuesToCreateRequest = (
  values: CreateAceOpportunityFormValues,
): CreateAceOpportunityRequest => {
  return {
    accessControls: getAccessControl(values),
    customer: getCustomer(values),
    lifeCycle: getLifecycle(values),
    marketing: getMarketing(values),
    opportunityType: values.opportunityType,
    partnerOpportunityTeam: getPartnerOpportunityTeam(values),
    primaryNeedsFromAws: values.primaryNeedsFromAws,
    project: getProject(values),
    solutions: values.solutions,
    awsProducts: values.awsProducts,
  };
};
