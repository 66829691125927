export enum AceOpportunityStageEnum {
  PROSPECT = 'Prospect',
  QUALIFIED = 'Qualified',
  TECHNICAL_VALIDATION = 'Technical Validation',
  BUSINESS = 'Business',
  BUSINESS_VALIDATION = 'Business Validation',
  COMMITTED = 'Committed',
  LAUNCHED = 'Launched',
  CLOSED_LOST = 'Closed Lost',
}
