import React from 'react';
import { IOneTimeKeyExchangeResponse } from 'packages/salesforce-canvas/src/lib/CanvasSession';

export default function CanvasCreateCoSell(props: {
  tackleSession: IOneTimeKeyExchangeResponse;
}) {
  return (
    <>
      <p style={{ maxWidth: '100%' }}>
        <pre>{JSON.stringify(props.tackleSession.context, null, 2)}</pre>
      </p>
    </>
  );
}
