import React, { useEffect, useMemo, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Tooltip,
  Typography,
} from 'vendor/material';
import { CheckboxGroup, Radio } from '@tackle-io/platform-ui';
import useStyles from './RadioCheckboxGroupFormField.styles';
import { InformationOutline } from 'mdi-material-ui';
import { useFormikContext } from 'formik';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { StandardEnum } from '../../types';
import { Label } from '../Label';
import { OpportunityYesNoEnum } from '../../types/enums';

interface RadioCheckboxGroupFormFieldProps {
  label: string;
  name: string;
  id: string;
  required?: boolean;
  tooltipText?: string;
  optionValuesEnum: StandardEnum<string>;
  optionLabelsEnum?: StandardEnum<string>;
  optionsHelperText?: string;
  delimiter?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  radioLabelYes?: string;
  radioLabelNo?: string;
  radioLabelYesHelperText?: string;
  radioLabelNoHelperText?: string;
  radioValueYes?: any;
  radioValueNo?: any;
}

export const RadioCheckboxGroupFormField: React.FC<
  RadioCheckboxGroupFormFieldProps
> = ({
  label,
  name,
  id,
  tooltipText,
  required,
  onChange,
  disabled,
  optionValuesEnum,
  optionLabelsEnum = null,
  optionsHelperText = null,
  delimiter,
  radioLabelYes = 'Yes',
  radioLabelNo = 'No',
  radioLabelYesHelperText,
  radioLabelNoHelperText,
  radioValueYes = OpportunityYesNoEnum.YES,
  radioValueNo = OpportunityYesNoEnum.NO,
}) => {
  const classes = useStyles();
  const { setFieldValue, setFieldTouched, values, touched, errors } =
    useFormikContext<UnifiedOpportunityFormValues>();
  const toggleInitialValue =
    delimiter &&
    Object.values(optionValuesEnum).find((option) =>
      values?.[name]?.includes(option),
    )
      ? radioValueYes
      : values?.[name];
  const [toggleShowMoreOptions, setToggleShowMoreOptions] =
    useState<string>(toggleInitialValue);

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event);
    if (event.target.value === OpportunityYesNoEnum.YES) {
      setFieldValue(name, null);
      setFieldTouched(name, true);
      setToggleShowMoreOptions(event.target.value);
      return;
    }
    setFieldValue(name, event.target.value);
    setFieldTouched(name, true);
    setToggleShowMoreOptions(event.target.value);
  };

  useEffect(() => {
    if (delimiter && values?.[name]?.includes(delimiter)) {
      setToggleShowMoreOptions(radioValueYes);
    }
    if (values?.[name] === radioValueNo) {
      setToggleShowMoreOptions(radioValueNo);
    }
  }, [delimiter, name, radioValueNo, radioValueYes, values]);

  const transformDeliminatedStringToOptions = (
    valuesFromSelectedOptions: (string | number)[],
  ): string => {
    if (valuesFromSelectedOptions?.length > 0) {
      return valuesFromSelectedOptions.join(delimiter).toString();
    }
    return null;
  };

  const handleCheckboxGroupChange = (selectedOptions) => {
    onChange && onChange(selectedOptions);
    delimiter
      ? setFieldValue(
          name,
          transformDeliminatedStringToOptions(selectedOptions),
        )
      : setFieldValue(name, selectedOptions);
    setFieldTouched(name, true);
  };

  const options = useMemo(() => {
    if (optionLabelsEnum) {
      return Object.entries(optionValuesEnum).map(
        ([optionKey, optionValue]) => {
          return { label: optionLabelsEnum[optionKey], value: optionValue };
        },
      );
    }
    return Object.values(optionValuesEnum).map((option: string) => {
      return { label: option, value: option };
    });
  }, [optionLabelsEnum, optionValuesEnum]);

  const checkboxValues = useMemo(() => {
    if (
      delimiter &&
      Object.values(optionValuesEnum).some((option) =>
        values?.[name]?.includes(option),
      )
    ) {
      return delimiter ? values?.[name]?.split(delimiter) : values?.[name];
    }
    return [];
  }, [delimiter, name, optionValuesEnum, values]);

  return (
    <FormControl
      aria-disabled={disabled}
      disabled={disabled}
      error={touched[name] && errors[name] && !values?.[name]}
      id={id}
      data-id={id}
    >
      <FormLabel className={classes.formLabel} data-id={`${id}Label`}>
        <Label label={label} required={required} />
        {tooltipText && (
          <Tooltip className={classes.tooltip} title={tooltipText}>
            <InformationOutline fontSize="small" />
          </Tooltip>
        )}
      </FormLabel>
      <MuiRadioGroup
        name={id}
        className={classes.radioGroup}
        onChange={handleFieldChange}
        value={toggleShowMoreOptions}
      >
        <FormControlLabel
          role={'presentation'}
          data-testid={`${id}_yes`}
          id={`${id}_yes`}
          data-id={`${id}_yes`}
          name={`${id}_yes`}
          label={radioLabelYes}
          value={radioValueYes}
          className={classes.radioButton}
          control={<Radio />}
        />
        <Typography className={classes.radioHelperText}>
          {radioLabelYesHelperText}
        </Typography>
        {toggleShowMoreOptions === radioValueYes && (
          <div className={classes.checkboxGroup}>
            {optionsHelperText && (
              <Typography className={classes.checkboxGroupHelperText}>
                {optionsHelperText}
              </Typography>
            )}
            <CheckboxGroup
              data-id={`${id}_options`}
              aria-disabled={disabled}
              disabled={disabled}
              options={options}
              onChange={handleCheckboxGroupChange}
              direction="vertical"
              values={checkboxValues}
            />
            <Typography className={classes.checkboxGroupText}>
              Select all that apply
            </Typography>
          </div>
        )}
        <FormControlLabel
          data-testid={`${id}_no`}
          role={'presentation'}
          id={`${id}_no`}
          data-id={`${id}_no`}
          name={`${id}_no`}
          label={radioLabelNo}
          value={radioValueNo}
          className={classes.radioButton}
          control={<Radio />}
        />
        <Typography className={classes.radioHelperText}>
          {radioLabelNoHelperText}
        </Typography>
      </MuiRadioGroup>
      {touched[name] && errors[name] && !values?.[name] ? (
        <FormHelperText>{errors[name]}</FormHelperText>
      ) : null}
    </FormControl>
  );
};
