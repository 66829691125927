import {
  AceNotNeededFromCloud,
  OpportunityIndustryEnum,
  OpportunityOriginationEnum,
  OpportunityStatusEnum,
  OpportunityYesNoEnum,
} from 'packages/cosell/src/types/enums';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';

export const UNITED_STATES_COUNTRY_CODE = 'US';
export const isFieldEditable = (opportunity) => {
  const hasCosellId = !!opportunity?.id;
  const isActionRequired =
    opportunity?.status === OpportunityStatusEnum.ACTION_REQUIRED;
  const isDraft =
    opportunity?.status === OpportunityStatusEnum.DRAFT ||
    opportunity?.status === OpportunityStatusEnum.INTERNAL_REVIEW;
  const isInboundAndAccepted =
    opportunity?.status === OpportunityStatusEnum.ACCEPTED &&
    opportunity?.origination === OpportunityOriginationEnum.INBOUND;

  return !hasCosellId || isDraft || isInboundAndAccepted || isActionRequired;
};

export const getSingleSelectFormOptions = (displayValueOption) => ({
  title: displayValueOption.display,
  value: displayValueOption.value,
});

export const handleIsOppFromMarketingActivityChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: Function,
) => {
  if (e.target.value === OpportunityYesNoEnum.NO) {
    setFieldValue('marketingActivityUseCases', null);
    setFieldValue('marketingActivityChannel', null);
    setFieldValue('isMarketingDevelopmentFunded', null);
  }
};

export const handlePartnerNeedTypeChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  setFieldValue: Function,
) => {
  if (
    e.target.value ===
    AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
  ) {
    setFieldValue('salesActivities', null);
    setFieldValue('primaryNeedsFromAws', null);
  }
};

export const handleIndustryChange = ({
  value,
  values,
  setFieldValue,
}: {
  value?: string;
  values: CreateAceOpportunityFormValues;
  setFieldValue: Function;
}) => {
  if (
    (!value || value !== OpportunityIndustryEnum.OTHER) &&
    values.industryOther
  ) {
    setFieldValue('industryOther', null);
  }
  if (
    (!value || value !== OpportunityIndustryEnum.GOVERNMENT) &&
    values.nationalSecurity
  ) {
    setFieldValue('nationalSecurity', null);
  }
};
