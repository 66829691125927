import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: theme.palette.NEUTRAL300,
    display: 'flex',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  radioGroup: {
    marginLeft: theme.spacing(2),
  },
  radioButton: {
    '& label': {
      marginRight: theme.spacing(1),
    },
    '& span': {
      fontWeight: 700,
      fontSize: theme.spacing(1.75),
      color: theme.palette.NEUTRAL800,
    },
  },
  radioHelperText: {
    fontSize: theme.spacing(1.75),
    color: theme.palette.NEUTRAL300,
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2.25),
  },
  checkboxGroup: {
    marginLeft: theme.spacing(2),
  },
  checkboxGroupHelperText: {
    color: theme.palette.NEUTRAL300,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 600,
  },
  checkboxGroupText: {
    color: theme.palette.NEUTRAL300,
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(0.5),
    fontSize: theme.spacing(1.5),
    fontWeight: 400,
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
