import { useEffect, useMemo, useState } from 'react';
import AceOpportunityForm from '../../components/UnifiedOpportunityForm/AceOpportunityForm/AceOpportunityForm';
import { DisplayCloudType } from '../../types/enums';
import { ErrorBoundary } from 'components';
import { ErrorPage } from 'components/ErrorPage/ErrorPage';
import { OpportunityProvider } from './providers/OpportunityProvider';
import { useCurrentUserVendorQuery } from 'generated/graphql';
import { authStore } from 'stores/Auth';
import {
  getUnifiedOpportunityView,
  UnifiedOpportunityView,
} from '../../utilities/constants';
import UnifiedOpportunityDetails from '../UnifiedOpportunityDetails';

interface RenderFormProps {
  cloud?: string;
  opportunityId?: string;
  view?: UnifiedOpportunityView;
}

const renderForm = ({ cloud, opportunityId, view }: RenderFormProps) => {
  if (cloud === DisplayCloudType.AWS) {
    switch (view) {
      case UnifiedOpportunityView.CREATE_NEW_CO_SELL_FORM:
        return <AceOpportunityForm />;
      case UnifiedOpportunityView.EDIT_CO_SELL_FORM:
        return <div>Edit page</div>;
      case UnifiedOpportunityView.CO_SELL_DETAILS:
        return <UnifiedOpportunityDetails opportunityId={opportunityId} />;
      default:
        return <div>Not found page</div>;
    }
  }
};

const UnifiedOpportunityForm = ({ match }) => {
  const cloud = useMemo(
    () => match.params.cloud.split('?')[0],
    [match.params.cloud],
  );
  const opportunityId = useMemo(
    () => match.params.opportunityId?.split('?')[0],
    [match.params.opportunityId],
  );
  const view = getUnifiedOpportunityView({ opportunityId, url: match.url });

  return (
    <UnifiedOpportunityFormContainer>
      {renderForm({ cloud, opportunityId, view })}
    </UnifiedOpportunityFormContainer>
  );
};

interface UnifiedOpportunityFormContainerProps {
  vendorIdFromCanvas?: string;
  tokenFromCanvas?: string;
  opportunityId?: string;
  children?: React.ReactElement;
}
export const UnifiedOpportunityFormContainer = ({
  vendorIdFromCanvas,
  tokenFromCanvas,
  opportunityId,
  children,
}: UnifiedOpportunityFormContainerProps) => {
  const [authDetails, setAuthDetails] = useState<{
    token?: string;
    vendorId?: string;
  }>({ vendorId: vendorIdFromCanvas, token: tokenFromCanvas });

  const { data: currentUserData } = useCurrentUserVendorQuery({
    nextFetchPolicy: 'cache-only',
    errorPolicy: 'all',
    notifyOnNetworkStatusChange: true,
    skip: !!vendorIdFromCanvas,
  });

  useEffect(() => {
    async function getDownstreamToken() {
      const downstreamToken = await authStore.getToken();
      setAuthDetails({
        token: downstreamToken,
        vendorId: currentUserData?.currentUser?.vendor?.id,
      });
    }

    if (!authDetails?.token && currentUserData?.currentUser?.vendor?.id) {
      getDownstreamToken();
    }
  }, [authDetails, currentUserData]);

  const isAuthenticated = authDetails.vendorId && authDetails.token;
  return (
    <ErrorBoundary renderError={() => <ErrorPage title="Co-sell" />}>
      <OpportunityProvider
        {...(isAuthenticated && authDetails)}
        opportunityId={opportunityId}
      >
        {children}
      </OpportunityProvider>
    </ErrorBoundary>
  );
};
export default UnifiedOpportunityForm;
