import React from 'react';
import { Box, Grid, Typography } from 'vendor/material';
import { ProviderIcon } from '@tackle-io/platform-ui';
// import { calculateDaysBetweenDateAndToday } from 'utils/dates';

import {
  DisplayCloudType,
  AceOpportunityReviewStatusEnum,
  TagColorEnum,
} from 'packages/cosell/src/types/enums';
import Tag from 'packages/cosell/src/components/Tag/Tag';
import { AceOpportunityResponse } from 'packages/cosell/src/types/responses/AceOpportunityResponse';

interface AceOpportunityDetailsHeaderProps {
  opportunity: AceOpportunityResponse; // Check the login in this component, we may not need the whole opportunity object here
}

export const AceOpportunityDetailsHeader: React.FC<
  AceOpportunityDetailsHeaderProps
> = ({ opportunity }) => {
  //TODO: check which date data we should use here
  // const shouldDisplayWarningCallout =
  //   Math.abs(calculateDaysBetweenDateAndToday(opportunity.lastModifiedDate)) >
  //     7 &&
  //   opportunity.lifeCycle?.reviewStatus ===
  //     AceOpportunityReviewStatusEnum.ACTION_REQUIRED;

  const shouldDisplayDraftTag =
    opportunity?.lifeCycle?.reviewStatus ===
    AceOpportunityReviewStatusEnum.PENDING_SUBMISSION;

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box mr={1}>
          <ProviderIcon fontSize={'large'} provider={DisplayCloudType.AWS} />
        </Box>

        <Typography variant="h5" component="h1">
          {opportunity?.project.title}
        </Typography>
      </Box>

      <Grid container spacing={1}>
        <Grid item>
          <Tag color={TagColorEnum.GREEN}>v14</Tag>
        </Grid>
        <Grid item>
          <Tag>{opportunity?.origin}</Tag>
        </Grid>
        <Grid item>
          {shouldDisplayDraftTag ? (
            <Tag>Draft</Tag>
          ) : (
            <Tag> {opportunity?.lifeCycle?.reviewStatus} </Tag>
          )}
        </Grid>
        <Grid item>
          <Tag>{opportunity?.lifeCycle?.stage}</Tag>
        </Grid>
      </Grid>
    </>
  );
};
