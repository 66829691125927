import { makeStyles } from 'vendor/material';

const useStyles = makeStyles((theme) => ({
  formLabel: {
    color: theme.palette.NEUTRAL300,
    display: 'flex',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 500,
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
  },
  radioGroup: {
    flexDirection: 'row',
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default useStyles;
