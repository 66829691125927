export enum CompetitiveTrackingEnum {
  ORACLE_CLOUD = 'Oracle Cloud',
  ON_PREM = 'On-Prem',
  CO_LOCATION = 'Co-location',
  AKAMAI = 'Akamai',
  ALICLOUD = 'AliCloud',
  GOOGLE_CLOUD_PLATFORM = 'Google Cloud Platform',
  IBM_SOFTLAYER = 'IBM Softlayer',
  MICROSOFT_AZURE = 'Microsoft Azure',
  OTHER_COST_OPTIMIZATION = 'Other- Cost Optimization',
  NO_COMPETITION = 'No Competition',
  OTHER = 'Other',
}
