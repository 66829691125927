import React, { useCallback, ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { OffersDataId } from '../../utils/intercomEnums';
import { Select } from '@tackle-io/platform-ui';
import { CurrencyCode, getCurrencyResource } from '../../utils/currency';
import { currencyCodeFormPath } from './utils';

interface FieldCurrencyCodeProps {
  hasUsageDimensions: boolean;
}

const fieldLabel = 'Currency';

const toCurrencySelectOption = (cc: CurrencyCode) => {
  const optionText = getCurrencyResource(cc);

  return (
    <option key={cc} value={cc}>
      {optionText}
    </option>
  );
};

const currencyOptions = Object.values(CurrencyCode).map(toCurrencySelectOption);

const onSelectHandler =
  (onChange: (v: string) => void) => (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target?.value || CurrencyCode.UnitedStatesDollar;
    onChange(value);
  };

const FieldCurrencyCode: React.FC<FieldCurrencyCodeProps> = ({
  hasUsageDimensions,
}) => {
  const { control, errors } = useFormContext();

  const fieldRenderer = useCallback(
    ({ ref, onChange, ...props }): React.ReactElement => (
      <Select
        {...props}
        id={currencyCodeFormPath}
        data-id={OffersDataId.PRIVATE_OFFERS_CURRENCY_FIELD}
        label={fieldLabel}
        defaultValue={CurrencyCode.UnitedStatesDollar}
        error={errors.pricing?.currencyCode?.message}
        onChange={onSelectHandler(onChange)}
        disabled={hasUsageDimensions}
      >
        {currencyOptions}
      </Select>
    ),
    [errors.pricing?.currencyCode?.message, hasUsageDimensions],
  );

  return (
    <Controller
      name={currencyCodeFormPath}
      control={control}
      render={fieldRenderer}
    />
  );
};

export default FieldCurrencyCode;
