import React from 'react';
import { Typography } from 'vendor/material';
import { Alert, Banner } from '@tackle-io/platform-ui';

export interface ErrorMessage {
  error?: {
    cause: {
      httpStatusCode?: string;
      message: {
        detail: string;
      };
    };
  };
  submissionErrors?: Array<string>;
}

export const ErrorFeedbackAlert: React.FC<ErrorMessage> = ({
  error,
  submissionErrors,
}) => {
  const subErrors = submissionErrors?.map((err) => {
    return err;
  });

  return (
    <div>
      {error !== undefined ? (
        <Alert
          appearance="danger"
          title={`${error.cause.httpStatusCode} - ${error.cause.message.detail} Please return to the Co-Sell page and select the opportunity again. If the issue persists, select "Support" from the Navigation menu to contact our Customer Journey team.`}
        />
      ) : (
        submissionErrors !== undefined && (
          <Banner
            title={`The following items need to be corrected:`}
            type="danger"
            isCollapsible={true}
            borderPosition="top"
            body={
              <ul>
                {subErrors.map((error) => {
                  return (
                    <Typography variant={`body2`}>
                      <li key={error}>{error}</li>
                    </Typography>
                  );
                })}
              </ul>
            }
          />
        )
      )}
    </div>
  );
};
