import { Grid } from 'vendor/material';
import { useFormikContext } from 'formik';
import {
  AiTextFormField,
  CurrencyFormField,
  DateFormField,
  MultiSelectFormField,
  RadioFormField,
  RadioCheckboxGroupFormField,
  SingleSelectFormField,
  TextFormField,
} from 'packages/cosell/src/components';

import { pendoIdGenerator } from 'utils/idGenerator';
import {
  cosellMarketingActivityFormSection,
  cosellOpportunityDetailsFormSection,
  cosellBuyerCompanyInformationFormSection,
  cosellAdditionalDetailsFormSection,
} from 'utils/pendoEnums';
import {
  AceNeedFromCloudEnumValues,
  AceNeedFromCloudEnumLabels,
  AceNotNeededFromCloud,
  AceOpportunityTypeEnum,
  AceUSStatesEnum,
  OpportunityIndustryEnum,
  OpportunityOriginationEnum,
  OpportunityStatusEnum,
  OpportunityYesNoEnum,
  CompetitiveTrackingEnum,
  FeatureFlagState,
  AceOpportunityMarketingActivityUseCaseEnum,
  CountryCodeEnum,
} from 'packages/cosell/src/types/enums';
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding';
import { useAmpliFeatureFlags } from '@tackle-io/tackle-auth-tools';
import { CoSellFeatureFlags } from 'utils/features';
import { UnifiedOpportunityFormSection } from '../UnifiedOpportunityFormSection';
import { UnifiedOpportunityResponse } from 'packages/cosell/src/types/responses/UnifiedOpportunityResponse';
import { AssignmentIcon, PersonIcon } from 'packages/cosell/assets';
import { useOpportunity } from 'packages/cosell/src/pages/UnifiedOpportunityForm/providers/OpportunityProvider';
import { OTHER } from '../FormFieldsConstants';
import { CreateAceOpportunityFormValues } from './AceOpportunityFormValues';
import {
  getSingleSelectFormOptions,
  handleIndustryChange,
  handleIsOppFromMarketingActivityChange,
  handlePartnerNeedTypeChange,
  UNITED_STATES_COUNTRY_CODE,
} from './helpers';
import { useEffect, useState } from 'react';
import { scrollToFirstError } from 'packages/cosell/src/utilities/scroll/scrollToFirstError';
import { isEmpty } from 'lodash';

interface AceOpportunityFormFieldsProps {
  opportunity: UnifiedOpportunityResponse;
}

const useCoSellFeatureFlags = () => {
  const { loading: ampliloading, flags: ampliflags } = useAmpliFeatureFlags();
  return {
    ampliloading,
    showAiGenerateButton:
      ampliflags[CoSellFeatureFlags.BETA_AI_COSELL_DESCRIPTION] ===
      FeatureFlagState.ON
        ? true
        : false,
  };
};

const AceOpportunityFormFields = ({
  opportunity,
}: AceOpportunityFormFieldsProps) => {
  const { values, setFieldValue, errors, isValid, isValidating, submitCount } =
    useFormikContext<CreateAceOpportunityFormValues>();
  const { awsEnumValues, solutionList } = useOpportunity();
  const { ampliloading, showAiGenerateButton } = useCoSellFeatureFlags();
  const isANewOpportunity = !opportunity?.id;
  const [hasScrolledToFirstError, setHasScrolledToFirstError] = useState(false);
  useEffect(() => {
    const shouldScrollToError =
      !isValid &&
      !isEmpty(errors) &&
      submitCount > 0 &&
      !hasScrolledToFirstError;
    if (!shouldScrollToError) {
      return;
    }

    const hasScrolled = scrollToFirstError();
    setHasScrolledToFirstError(hasScrolled);
  }, [errors, isValidating, isValid, submitCount, hasScrolledToFirstError]);

  useEffect(() => {
    setHasScrolledToFirstError(false);
  }, [submitCount]);

  /**
   * An opportunity is considered closed if it is in closed lost or closed won status.
   */
  const isClosedOpportunity =
    opportunity?.status === OpportunityStatusEnum.CLOSED_LOST ||
    opportunity?.status === OpportunityStatusEnum.CLOSED_WON;

  /**
   * An opportunity is considered submitted if it is not in draft,or internal review status.
   * Action required is different from the ones above because it is a status that requires the user to take action due to an error.
   * By submitted, we mean that the opportunity has been sent to AWS and the editable fields are limited.
   */
  const hasBeenSubmitted =
    ![
      OpportunityStatusEnum.DRAFT,
      OpportunityStatusEnum.INTERNAL_REVIEW,
      OpportunityStatusEnum.ACTION_REQUIRED,
    ].includes(opportunity?.status) && !isANewOpportunity;

  /**
   * An opportunity is considered not sent to AWS when it is created within Tackle and has not been submitted to AWS or
   * is created in the Salesforce app and has internal review enabled.
   */
  const hasNotBeenSentToAWS =
    [
      OpportunityStatusEnum.DRAFT,
      OpportunityStatusEnum.INTERNAL_REVIEW,
    ].includes(opportunity?.status) || isANewOpportunity;

  // const isOpportunityDraftOrNew =
  //   opportunity?.status === OpportunityStatusEnum.DRAFT || isANewOpportunity;

  const isOppFromMarketingActivity =
    values?.isOppFromMarketingActivity === OpportunityYesNoEnum.YES;

  /**
   * Due to unknown values coming from AWS, we need to disable validation for inbound opportunities on certain fields.
   */
  const shouldDisableValidationForInbounds =
    opportunity?.origination === OpportunityOriginationEnum.INBOUND
      ? OpportunityStatusEnum.ACCEPTED
        ? !hasBeenSubmitted
        : hasBeenSubmitted
      : hasBeenSubmitted;

  return (
    <>
      <div>
        {/* Section 1: Customer Details */}
        <UnifiedOpportunityFormSection
          title="Customer details"
          titleIcon={<OfficeBuilding />}
        >
          <Grid item xs={12}>
            <TextFormField
              name="dunsNumber"
              label="Customer data universal number system (DUNS)"
              helperText="Customer DUNS is 9 digits. For example: 123456789"
              pendoId={pendoIdGenerator(
                cosellBuyerCompanyInformationFormSection.COSELL_BUYER_COMPANY_INFORMATION_DUNS_NUMBER,
              )}
              disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={hasBeenSubmitted || isClosedOpportunity}
              // aria-invalid={
              //   touched['customerCompanyName'] && errors['customerCompanyName']
              // }
              name="customerCompanyName"
              label="Company name"
              // disabled={hasBeenSubmitted || isClosedOpportunity}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              // aria-disabled={
              //   shouldDisableValidationForInbounds || isClosedOpportunity
              // }
              // disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
              fieldId="industry"
              label="Industry vertical"
              onChange={(value) =>
                handleIndustryChange({
                  value,
                  values,
                  setFieldValue,
                })
              }
              optionsList={OpportunityIndustryEnum}
              required
            />
          </Grid>
          {values.industry === OpportunityIndustryEnum.OTHER ? (
            <Grid item xs={12}>
              <TextFormField
                name="industryOther"
                label="Industry other"
                // disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
              />
            </Grid>
          ) : null}
          {values.industry === OpportunityIndustryEnum.GOVERNMENT &&
          values.countryCode === UNITED_STATES_COUNTRY_CODE ? (
            <Grid item xs={12}>
              <RadioFormField
                name="nationalSecurity"
                label="Classified national security information"
                id={'data-id'}
                required
                // disabled={!hasNotBeenSentToAWS}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={hasBeenSubmitted || isClosedOpportunity}
              // aria-invalid={touched['customerWebsite'] && errors['customerWebsite']}
              name="customerWebsite"
              label="Customer website"
              // disabled={hasBeenSubmitted || isClosedOpportunity}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              // aria-disabled={
              //   shouldDisableValidationForInbounds || isClosedOpportunity
              // }
              fieldId="countryCode"
              data-testid="countrySingleSelectFormField"
              label="Country"
              optionsList={
                awsEnumValues?.['customer.account.address.countryCode']?.map(
                  (option) => getSingleSelectFormOptions(option),
                ) || CountryCodeEnum
              }
              // disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={
              //   shouldDisableValidationForInbounds || isClosedOpportunity
              // }
              // aria-invalid={touched['postalCode'] && errors['postalCode']}
              name="postalCode"
              label="Postal code"
              helperText={
                values.countryCode === UNITED_STATES_COUNTRY_CODE &&
                'United States postal code must be in 99999 OR 99999-9999 format.'
              }
              // disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
              required
            />
          </Grid>
          {values.countryCode === UNITED_STATES_COUNTRY_CODE ? (
            <Grid item xs={12}>
              <SingleSelectFormField
                // aria-disabled={
                //   shouldDisableValidationForInbounds || isClosedOpportunity
                // }
                data-testid="stateTextFormField"
                fieldId="state"
                label="State/Province"
                optionsList={AceUSStatesEnum}
                disabled={
                  shouldDisableValidationForInbounds || isClosedOpportunity
                }
                required
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={
              //   shouldDisableValidationForInbounds || isClosedOpportunity
              // }
              // aria-invalid={touched['city'] && errors['city']}
              name="city"
              label="City"
              disabled={
                shouldDisableValidationForInbounds || isClosedOpportunity
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={
              //   shouldDisableValidationForInbounds || isClosedOpportunity
              // }
              // aria-invalid={touched['address'] && errors['address']}
              name="address"
              label="Address"
              disabled={
                shouldDisableValidationForInbounds || isClosedOpportunity
              }
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 2: Project details */}
        <UnifiedOpportunityFormSection
          title="Project details"
          titleIcon={<AssignmentIcon />}
        >
          <Grid item xs={12}>
            <RadioCheckboxGroupFormField
              // aria-disabled={isClosedOpportunity}
              // disabled={isClosedOpportunity}
              id="partnerNeedType"
              label="Partner primary need from AWS"
              optionValuesEnum={AceNeedFromCloudEnumValues}
              optionLabelsEnum={AceNeedFromCloudEnumLabels}
              optionsHelperText={'Partner specific needs from AWS for co-sell'}
              radioLabelYes={'Co-sell with AWS'}
              radioLabelNo={'Do not need support from AWS sales rep'}
              radioLabelYesHelperText={
                'Share the opportunity with AWS to receive deal assistance and support.'
              }
              radioLabelNoHelperText={
                'Share the opportunity with AWS for visibility only, you will not receive deal assistance or support.'
              }
              radioValueNo={
                AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              required
              name={'partnerNeedType'}
              onChange={(e) => handlePartnerNeedTypeChange(e, setFieldValue)}
            />
          </Grid>
          {values.partnerNeedType !==
            AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP && (
            <Grid item xs={12}>
              <MultiSelectFormField
                aria-disabled={isClosedOpportunity}
                fieldId={'primaryNeedsFromAws'}
                label={'Partner specific needs from AWS'}
                options={awsEnumValues && awsEnumValues['primaryNeedFromAWS']}
                required={
                  values.partnerNeedType !==
                  AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
                }
                filterSelectedOptions={true}
              />
            </Grid>
          )}
          {/* TODO: Update this to a RadioGroupFormField */}
          <Grid item xs={12}>
            <SingleSelectFormField
              fieldId="opportunityType"
              label="Opportunity type"
              optionsList={AceOpportunityTypeEnum}
              filterSelectedOptions={true}
              required
              disabled={isClosedOpportunity}
            />
          </Grid>
          {values.opportunityType === AceOpportunityTypeEnum.FLAT_RENEWAL ? (
            <Grid item xs={12}>
              <TextFormField
                label="Parent co-sell ID"
                name="parentOppId"
                pendoId={pendoIdGenerator(
                  cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_PARENT_COSELL_ID,
                )}
                disabled={isClosedOpportunity}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="projectTitle"
              label="Partner project title"
              multiline={true}
              maxLength={255}
              rows={6}
              required
              // aria-invalid={touched['projectTitle'] && errors['projectTitle']}
              disabled={!hasNotBeenSentToAWS}
              aria-disabled={!hasNotBeenSentToAWS}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              aria-disabled={isClosedOpportunity}
              fieldId={'salesActivities'}
              label={'Sales activities'}
              options={awsEnumValues && awsEnumValues['project.salesActivity']}
              disabled={isClosedOpportunity}
              // Sales activities is required when the partner needs support from AWS.
              required={
                values.partnerNeedType !==
                AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              helperText="Choose sales activities that have happened with the end-customer."
              filterSelectedOptions={true}
            />
          </Grid>
          <Grid item xs={12}>
            {!ampliloading && showAiGenerateButton ? (
              <AiTextFormField
                // aria-disabled={
                //   shouldDisableValidationForInbounds || isClosedOpportunity
                // }
                // aria-invalid={
                //   touched['projectDescription'] && errors['projectDescription']
                // }
                name="projectDescription"
                dependsOnFields={['customerWebsite']}
                label="Buyer business problem"
                multiline
                maxLength={599}
                minLength={20}
                placeholder="Add your own description, or select Generate with Tackle AI to get started"
                required
                disabled={
                  shouldDisableValidationForInbounds || isClosedOpportunity
                }
              />
            ) : (
              <TextFormField
                pendoId={
                  cosellAdditionalDetailsFormSection.COSELL_ADDITIONAL_DETAILS_BUYER_BUSINESS_PROBLEM
                }
                // aria-disabled={
                //   shouldDisableValidationForInbounds || isClosedOpportunity
                // }
                // aria-invalid={
                //   touched['projectDescription'] && errors['projectDescription']
                // }
                name="projectDescription"
                label="Buyer business problem"
                multiline
                rows={6}
                maxLength={32000}
                minLength={50}
                required
                disabled={
                  shouldDisableValidationForInbounds || isClosedOpportunity
                }
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              aria-disabled={isClosedOpportunity}
              fieldId="solutions"
              label="Solutions offered"
              options={solutionList}
              disabled={isClosedOpportunity || solutionList?.length === 0}
              required
              filterSelectedOptions={true}
              dataId={pendoIdGenerator(
                cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_SOLUTIONS,
              )}
            />
          </Grid>
          {values.solutions?.includes(OTHER) ? (
            <Grid item xs={12}>
              <TextFormField
                name="otherSolutionDescription"
                label="Other solution offered"
                // disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <MultiSelectFormField
              // aria-disabled={isClosedOpportunity}
              fieldId={'awsProducts'}
              label={'AWS products'}
              options={[]}
              // disabled={isClosedOpportunity}
              // Sales activities is required when the partner needs support from AWS.
              required={
                false
                // values.partnerNeedType !==
                // AceNotNeededFromCloud.DO_NOT_NEED_SUPPORT_FROM_AWS_SALES_REP
              }
              filterSelectedOptions={true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="nextStep"
              label="Next step"
              rows={6}
              multiline
              placeholder="Describe the next steps for this opportunity"
              maxLength={255}
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <SingleSelectFormField
              // aria-disabled={isClosedOpportunity}
              // disabled={isClosedOpportunity}
              fieldId="useCase"
              filterSelectedOptions={true}
              label="Use case"
              optionsList={AceOpportunityMarketingActivityUseCaseEnum}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId="deliveryModel"
              filterSelectedOptions={true}
              label="Delivery model"
              options={
                awsEnumValues && awsEnumValues['softwareRevenue.deliveryModel']
              }
              required
            />
          </Grid>
          <Grid item xs={12}>
            <CurrencyFormField
              dataId={pendoIdGenerator(
                cosellOpportunityDetailsFormSection.COSELL_OPPORTUNITY_DETAILS_ESTIMATED_AWS_MONTHLY_RECURRING_REVENUE,
              )}
              aria-disabled={isClosedOpportunity}
              name="expectedMonthlyRevenue"
              label="Estimated AWS monthly recurring revenue"
              required
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <DateFormField
              // aria-disabled={isClosedOpportunity}
              name="targetCloseDate"
              label="Target close date"
              required
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <MultiSelectFormField
              fieldId="apnPrograms"
              label={'APN programs'}
              options={[]}
              filterSelectedOptions={true}
              required={false}
              // disabled={isClosedOpportunity}
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 3: Marketing details */}
        <UnifiedOpportunityFormSection
          title="Marketing details"
          titleIcon={<AssignmentIcon />}
          tooltip="This helps AWS progress faster on the opportunity. If not filled, AWS will populate with opportunity submitter information."
        >
          <Grid item xs={12}>
            <RadioFormField
              // aria-disabled={isClosedOpportunity}
              label={'Opportunity source'}
              id={pendoIdGenerator(
                cosellMarketingActivityFormSection.IS_OPP_FROM_MARKETING_ACTIVITY,
              )}
              name="isOppFromMarketingActivity"
              required
              onChange={(e) =>
                handleIsOppFromMarketingActivityChange(e, setFieldValue)
              }
              // disabled={isClosedOpportunity}
            />
          </Grid>
          {isOppFromMarketingActivity && (
            <>
              <Grid item xs={12}>
                <TextFormField
                  // aria-disabled={isClosedOpportunity}
                  pendoId={pendoIdGenerator(
                    cosellMarketingActivityFormSection.AWS_MARKETING_SALESFORCE_CAMPAIGN_NAME,
                  )}
                  name="campaignName"
                  label="Marketing campaign"
                  // disabled={isClosedOpportunity}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  // aria-disabled={isClosedOpportunity}
                  fieldId={'marketingActivityUseCases'}
                  label={'Marketing activity use case'}
                  options={[]}
                  // disabled={isClosedOpportunity}
                  required={false}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <MultiSelectFormField
                  // aria-disabled={isClosedOpportunity}
                  fieldId={'marketingActivityChannel'}
                  label={'Marketing activity channel'}
                  options={awsEnumValues && awsEnumValues['marketing.channels']}
                  // disabled={isClosedOpportunity}
                  required={false}
                  filterSelectedOptions={true}
                />
              </Grid>
              <Grid item xs={12}>
                <RadioFormField
                  // aria-disabled={isClosedOpportunity}
                  label={'Marketing development funds used?'}
                  name="isMarketingDevelopmentFunded"
                  id={pendoIdGenerator(
                    cosellMarketingActivityFormSection.WERE_MARKETING_DEVELOPMENT_FUNDS_USED,
                  )}
                  required
                  // disabled={isClosedOpportunity}
                />
              </Grid>
            </>
          )}
        </UnifiedOpportunityFormSection>

        {/* Section 4: Additional Details */}
        <UnifiedOpportunityFormSection
          title="Additional details"
          titleIcon={<AssignmentIcon />}
        >
          <Grid item xs={12}>
            <SingleSelectFormField
              // aria-disabled={
              //   shouldDisableValidationForInbounds || isClosedOpportunity
              // }
              fieldId="competitiveTracking"
              data-testid="competitiveTracking"
              label="Competitive tracking"
              optionsList={CompetitiveTrackingEnum}
              // disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
            />
          </Grid>
          {values.competitiveTracking === CompetitiveTrackingEnum.OTHER ? (
            <Grid item xs={12}>
              <TextFormField
                name="otherCompetitorNames"
                label="Other competitors"
                // disabled={shouldDisableValidationForInbounds || isClosedOpportunity}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <TextFormField
              name="awsAccountID"
              label="AWS account ID"
              helperText="12 digit number. For example: 123456789012"
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              name="additionalComments"
              label="Additional comments"
              multiline
              rows={6}
              helperText="Enter project description, opportuntinty details, customer pain points, customer needs, etc."
              maxLength={255}
            />
          </Grid>
        </UnifiedOpportunityFormSection>
        {/* Section 5: Customer contact */}
        <UnifiedOpportunityFormSection
          title="Customer contact"
          titleIcon={<PersonIcon />}
        >
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="customerFirstName"
              label="Customer first name"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="customerLastName"
              label="Customer last name"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="customerTitle"
              label="Customer title"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="customerEmail"
              label="Customer email"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="customerPhone"
              label="Customer phone"
              // disabled={isClosedOpportunity}
            />
          </Grid>
        </UnifiedOpportunityFormSection>

        {/* Section 6: Partner contact */}
        <UnifiedOpportunityFormSection
          title="Partner contact"
          titleIcon={<PersonIcon />}
          tooltip="AWS sales team may reach out to this contact in regard to the opportunity."
        >
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="primaryContactFirstName"
              label="Primary contact first name"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="primaryContactLastName"
              label="Primary contact last name"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="primaryContactTitle"
              label="Primary contact title"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="primaryContactEmail"
              label="Primary contact email"
              // disabled={isClosedOpportunity}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFormField
              // aria-disabled={isClosedOpportunity}
              name="primaryContactPhone"
              label="Primary contact phone"
              // disabled={isClosedOpportunity}
            />
          </Grid>
        </UnifiedOpportunityFormSection>
      </div>
    </>
  );
};

// const ProjectDescriptionCallout = () => {
//   return (
//     <>
//       <p>
//         Describe any pre-sales efforts related to the opportunity (e.g., meeting
//         with the end-user decision-makers, defining technology stack on AWS,
//         etc.) and a clear description of the customer needs and your proposed
//         solution.
//       </p>
//       <p>Provide the following details:</p>
//       <ul>
//         <li>
//           What is the end customer looking to do on AWS? What is your role, the
//           APN Partner, in this project?
//         </li>
//         <li>
//           What are the software applications and tools used to support the
//           solution?
//         </li>
//         <li>
//           Specific department or subsidiary of the end customer you, the APN
//           partner, are working with?
//         </li>
//       </ul>
//       <p>Minimum 50 characters description required.</p>
//     </>
//   );
// };

// We need to conditionally render a tooltip around the Project Description field
// because the disabled styles of the field are not applied when the field is
// is wrapped in the tooltip.
// const CalloutWrapper = ({
//   isTextFieldDisabled,
//   wrapper,
//   children,
// }: {
//   isTextFieldDisabled: boolean;
//   wrapper: React.FC<JSX.Element>;
//   children: JSX.Element;
// }) => (isTextFieldDisabled ? children : wrapper(children));

export default AceOpportunityFormFields;
