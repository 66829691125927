import { ComboBox } from '@tackle-io/platform-ui';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { SelectFormFieldOption } from '../../types';
import { Box } from 'vendor/material';
import { UnifiedOpportunityFormValues } from '../UnifiedOpportunityForm';
import { Label } from '../Label';

const DEFAULT_OPTION = '--None--';

interface MultiSelectFormFieldProps {
  disabled?: boolean;
  fieldId: string;
  filterSelectedOptions?: boolean;
  label: string;
  options: Array<{ value: string; display: string }>;
  placeholder?: string;
  required: boolean;
  helperText?: string;
  dataId?: string;
}

export const MultiSelectFormField: React.FC<MultiSelectFormFieldProps> = ({
  disabled,
  fieldId,
  filterSelectedOptions,
  label,
  options,
  placeholder,
  helperText,
  required,
  dataId = null,
}) => {
  const { values, errors, touched, setFieldTouched, setFieldValue } =
    useFormikContext<UnifiedOpportunityFormValues>();

  // It's important to memoize options here because our multiselct field
  // determines options and selected option equality by reference.
  const memoizedOptions: SelectFormFieldOption[] = useMemo(() => {
    const optionsToMap = options ? options : [];
    return optionsToMap.map((x) => ({
      title: x.display,
      value: x.value,
    }));
  }, [options]);

  const handleMultiSelectChange = (
    event: React.SyntheticEvent<Element, Event>,
    selectedOptions: SelectFormFieldOption[],
  ) => {
    setFieldTouched(fieldId, true);

    const valuesFromSelectedOptions = selectedOptions?.map(
      (option) => option.value,
    );

    setFieldValue(fieldId, valuesFromSelectedOptions);
  };

  const value = memoizedOptions?.filter((x) =>
    values?.[fieldId]?.includes(x.value),
  );
  const comboBoxLabel = dataId ? (
    <Box data-id={dataId}>
      <Label label={label} required={required} />
    </Box>
  ) : (
    <Label label={label} required={required} />
  );
  return (
    <ComboBox
      disabled={disabled}
      hasError={touched[fieldId] && errors?.[fieldId]}
      filterSelectedOptions={filterSelectedOptions}
      id={fieldId}
      label={comboBoxLabel}
      multiple={true}
      onChange={handleMultiSelectChange}
      options={memoizedOptions}
      value={value}
      placeholder={placeholder ? placeholder : DEFAULT_OPTION}
      description={touched[fieldId] && errors?.[fieldId]}
    />
  );
};
